// router.js
import { createRouter, createWebHistory } from 'vue-router'
import portData from "./assets/json/portfolio.json"

const loadPath = (section) => {
    // console.log("router loadPath section", section)
    return (section.routerPath)
}

const loadName = (section) => {
    return section.displayName
}

const loadComponent = (section) => {
    let component;
    if (Array.isArray(section.component)) {
        component = section.component[0];
    } else if (typeof section.component === 'string') {
        component = section.component
    } else {
        console.error("Router could not parse section.component")
    }

    console.log("router loadComponent component", component);

    return () => import(`@/components/${component}.vue`);
};

const loadMeta = (section) => {

    if (section.component.includes("CircleGridGallery")) {
        return {
            title: section.title,
            extended: true
        }
    }
    else {
        return {
            title: section.title,
            extended: false
        }
    }
}

const loadProps = (section, marketKey) => {

    function processCircleGridGallery() {
        const galleries = Object.values(section.galleries || {});
        const projList = [];
        galleries.forEach((gal) => {
            Object.keys(gal.projects || {}).forEach((proj) => {
                const projObj = portData.projects?.[proj];
                if (projObj) {
                    // make a copy of the project object in case any other sections use the same object
                    const copyObj = structuredClone(projObj);
                    // Add in key
                    copyObj["key"] = proj;
                    // Add in sections to show
                    const sections = gal.projects[proj]["sectionList"];
                    copyObj["sectionsToShow"] = sections;
                    projList.push(copyObj);
                } else {
                    console.warn(`loadProps Project object not found for ${proj} in portData.projects`);
                }
            });
        });
        console.log('loadProps Final projList:', projList);
        return {
            name: section.displayName,
            projectData: projList
        };
    }

    console.log("loadProps marketKey", marketKey);

    try {

        let component;
        if (Array.isArray(section.component)) {
            component = section.component[0];
        } else if (typeof section.component === 'string') {
            component = section.component
        } else {
            console.error("Router could not parse section.component")
        }

        console.log("router loadProps component", section.component);
        switch (component) {
            case "CircleGridGallery": {
                const propObj = processCircleGridGallery();
                console.log("loadProps CGG propObj", propObj);
                return propObj;
            }
            case "ScrollGalleryTwoImages":
                return {
                    name: section.displayName,
                    galleriesData: section.galleries
                };
            case "ScrollGallerySingleImage":
                return {
                    name: section.displayName,
                    galleriesData: section.galleries
                };
            case "AboutMe":
                var aboutType = "cv";
                if (portData.markets[marketKey]) {
                    aboutType = portData.markets[marketKey].aboutType;
                }
                console.log("router AboutMe aboutType", aboutType);
                return {
                    name: section.displayName,
                    aboutType: aboutType
                }
            default:
                return {
                    name: section.displayName,
                    galleriesData: section.galleries
                };
        }

    }
    catch (error) {
        console.error(`Error in loadProps for section ${section.name}:`, error);
        return;
    }

}

const sections = Object.values(portData.sections || {});
// Get root page
const rootSection = sections.find(section => section.default === true) || sections[0];
if (!rootSection) {
    console.error("No Home Page Found")
}

const routes = [
    // Home Page
    {
        path: '/',
        redirect: to => {
            return { path: loadPath(rootSection), query: to.query }
        }
    },
    // Add routes for all sections except the root
    ...sections
        .filter(section => section.path !== '/')
        .map(section => ({
            path: loadPath(section),
            name: loadName(section),
            component: loadComponent(section),
            meta: loadMeta(section),
            props: route => ({
                ...loadProps(section, route.query["mk"]),
                query: route.query
            })
        })),

    // // Catch-all route for unmatched paths
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/components/NotFound.vue'),
        meta: { title: "Nothing Quite Sounds Like A . . ." },

    }
];

const baseUrl = "/";
const router = createRouter({
    history: createWebHistory(baseUrl),
    routes
});

// Set Document Title
router.beforeEach((to, from, next) => {
    // Set document title
    if (to.meta && to.meta.title) {
        document.title = to.meta.title 
    } else {
        document.title = "David Moreau Design"
    }

    // Preserve query parameters
    const newQuery = { ...from.query, ...to.query }
    if (JSON.stringify(to.query) !== JSON.stringify(newQuery)) {
        next({ ...to, query: newQuery })
    } else {
        next()
    }
});

export default router;